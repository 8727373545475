
/**
 *
 * Created by Filipe - 24/09/2022
 *
 */
import {computed, reactive} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";

import {useStore} from '@/store'
import {User} from "@/interfaces/users";

interface State {
    loggedUser: User | null,
    userTitle: string,
    isUserAdmin: boolean,
    showMenuItems: boolean,
    appVersion: string,

}


export default {
    name: "NavbarMenu",

    setup() {

        const store = useStore();
        const route = useRoute();

        const state: State = reactive({
            loggedUser: computed<User | null>(() => store.getters.getLoggedUser),
            userTitle: computed<string>(() => `Hello, ${store.getters.getLoggedUser ? 'User' : store.getters.getLoggedUser.name}`),
            isUserAdmin: computed<boolean>(() => !state.loggedUser || !state.loggedUser.profile ? false : state.loggedUser.profile.description === 'admin'),
            showMenuItems: computed<boolean>(() => !!store.getters.getLoggedUser && route.name !== 'login' && route.name !== 'recoverPassword'),
            appVersion: computed<string>(() => store.getters.getLoggedUser === null || !store.getters.getLoggedUser.appVersion ? '' : 'v' + store.getters.getLoggedUser.appVersion),
        })

        const logoutHandler = () => {
            axios.post('/logout')
            window.location.assign('/jbay-login')
        }

        return {
            route,
            state,
            logoutHandler
        }
    }
}
