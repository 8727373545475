import {InjectionKey} from 'vue';
import {createStore, Store, useStore as baseUseStore} from 'vuex'
import axios from "axios";

import {AcknowledgeData, AlertData, DialogData} from '@/interfaces/common'
import {User} from "@/interfaces/users";
import {USERS_REST_API_URL} from "@/urls";

import clients from "@/store/modules/clients";


// https://vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function

// define your typings for the store state
export interface State {
    showMask: boolean
    alertid: number
    alertsList: AlertData[]
    dialogData: DialogData
    acknowledgeData: AcknowledgeData
    loggedUser: User | null
}


// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

// define your own `useStore` composition function
export function useStore () {
    return baseUseStore(key)
}

const state: State = {
    showMask: false,
    alertid: 0, //aux
    alertsList: [],
    dialogData: {
        message: '',
    },
    acknowledgeData: {
        message: '',
    },
    loggedUser: null
}


export const store = createStore<State>({
    state,
    getters: {
        getShowMask: (state) => state.showMask,
        getAlertsList: state => state.alertsList,
        getDialogData: state => state.dialogData,
        getAcknowledgeData: state => state.acknowledgeData,
        getLoggedUser: (state): User | null => state.loggedUser,
    },
    mutations: {
        showMask: (state, value) => {
            if (value) {
                state.showMask = true
            } else {
                state.showMask = false
            }
        },
        showAlert(state, alert: AlertData) {
            alert.id = state.alertid++
            state.alertsList.push(alert)
            setTimeout(() => {
                state.alertsList.shift()
            }, 5000)// 5000 = 5s
        },
        showDialog(state, data: DialogData) {
            if (data) {
                state.dialogData.title = data.title ? data.title : 'Are you sure?'
                state.dialogData.btnStyle = data.btnStyle;
                state.dialogData.message = data.message
                state.dialogData.type = data.type
                state.dialogData.successCallback = data.successCallback ? data.successCallback : null
                state.dialogData.show = true
            } else {
                state.dialogData.show = false
            }
        },

        showAcknowledge(state, data: AcknowledgeData) {
            if (data) {
                state.acknowledgeData.title = data.title
                state.acknowledgeData.message = data.message
                state.acknowledgeData.btnStyle = data.btnStyle
                state.acknowledgeData.type = data.type
                state.acknowledgeData.show = true
            } else {
                state.acknowledgeData.show = false
            }
        },
        setLoggedUser(state: State, user: User) {
            state.loggedUser = user
        },
    },

    actions: {
        loadWhoAmI(context) {
            return axios.get(`${USERS_REST_API_URL}/whoami`)
                .then(response => {
                    // console.debug('Who Am I response: ', response)
                    if (response.data) {
                        context.commit('setLoggedUser', response.data)
                    } else {
                        context.commit('setLoggedUser', null)
                    }
                    return response
                }).catch((/*error*/) => {
                    // console.error('error loading logged user ', error)
                    context.commit('setLoggedUser', null)
                })
        },
    },
    modules: {
        clients
    }
})
