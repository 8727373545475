import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff0c15a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal",
  "data-bs-backdrop": "static",
  id: "my_modal"
}
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_font_awesome_icon, {
          icon: "spinner",
          size: "5x",
          spin: "",
          style: { color: '#dcdcdc' }
        })
      ])
    ])
  ]))
}