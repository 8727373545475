import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [

    {
        path: '/admin',
        meta: {
            module: 'admin'
        },
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminModule.vue'),
        children: [
            {
                path: 'users',
                name: 'usersList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UsersListTab.vue')
            },
            {
                path: 'users/:uuid/user',
                name: 'userDetails',
                props: true,
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/UserDetailsPanel.vue')
            },
            {
                path: 'settings',
                name: 'settingsList',
                meta: {
                    module: 'admin'
                },
                component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/SettingsTab.vue')
            },
        ]
    },

    {
        path: '/',
        name: 'home',
        meta: {
            module: 'app'
        },
        component: HomeView
    },
    {
        path: '/jbay-login',
        name: 'login',
        meta: {
            module: 'login'
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue')
    },
    {
        path: '/password-recover/:code',
        name: 'recoverPassword',
        props: true,
        meta: {
            module: 'app'
        },
        component: () => import(/* webpackChunkName: "recoverpassword" */ '@/views/RecoverPassword.vue')
    },
    {
        path: '/clientes',
        meta: {
            module: 'clientes'
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/ClientesModule.vue'),
        children: [
            {
                path: '',
                name: 'clientesMainPanel',
                meta: {
                    module: 'clientes'
                },
                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/ClientesMainPanel.vue')
            },
            {
                path: ':uuid/client',
                // props: true,
                meta: {
                    module: 'clientes'
                },
                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/ClientDetailsSubmodule.vue'),
                children: [
                    {
                        path: '',
                        name: 'clientDetails',
                        meta: {
                            module: 'clientes'
                        },
                        component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/ClientTabsPanel.vue'),
                    },
                    {
                        path: ':projectUuid/project',
                        /*name: 'clientProject',*/
                        // props: true,
                        meta: {
                            module: 'clientes'
                        },
                        component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProjetosModule.vue'),
                        children: [
                            {
                                path: 'details',
                                name: 'clientProjectDetails',
                                meta: {
                                    module: 'clientes'
                                },
                                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProjectDetailsTab.vue'),
                            },
                            {
                                path: 'proposals',
                                name: 'clientProjectProposals',
                                meta: {
                                    module: 'clientes'
                                },
                                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProjectProposalsTab.vue'),
                            },
                            {
                                path: 'proposals/:uuidProposal/proposal',
                                name: 'clientProposalDetails',
                                props: true,
                                meta: {
                                    module: 'clientes'
                                },
                                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProposalDetailsPanel.vue'),
                            },
                            {
                                path: 'quotes',
                                name: 'clientProjectQuotes',
                                meta: {
                                    module: 'clientes'
                                },
                                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProjectQuotesTab.vue'),
                            },
                            {
                                path: 'documents',
                                name: 'clientProjectDocuments',
                                meta: {
                                    module: 'clientes'
                                },
                                component: () => import(/* webpackChunkName: "clientes" */ '@/views/clientes/projects/ProjectDocumentsTab.vue'),
                            },


                        ]
                    },

                ]
            },
        ]
    },
    /*{
        path: '/projetos',
        meta: {
            module: 'projetos'
        },
        component: () => import(/!* webpackChunkName: "projetos" *!/ '@/views/clientes/projects/ProjetosModule.vue'),
        children: [
            {
                path: '',
                name: 'projetosMainPanel',
                meta: {
                    module: 'projetos'
                },
                component: () => import(/!* webpackChunkName: "projetos" *!/ '@/views/projetos/ProjetosMainPanel.vue')
            },
            {
                path: 'novo-projeto',
                name: 'createProjeto',
                meta: {
                    module: 'projetos'
                },
                component: () => import(/!* webpackChunkName: "projetos" *!/ '@/views/projetos/ProjetoDataPanel.vue')
            },
            {
                path: ':uuid/editar-projeto',
                name: 'editProjeto',
                props: true,
                meta: {
                    module: 'projetos'
                },
                component: () => import(/!* webpackChunkName: "projetos" *!/ '@/views/projetos/ProjetoDataPanel.vue')
            },


        ]
    },
    {
        path: '/propostas',
        meta: {
            module: 'propostas'
        },
        component: () => import(/!* webpackChunkName: "propostas" *!/ '@/views/propostas/PropostasModule.vue'),
        children: [
            {
                path: '',
                name: 'propostasMainPanel',
                meta: {
                    module: 'propostas'
                },
                component: () => import(/!* webpackChunkName: "propostas" *!/ '@/views/propostas/PropostasMainPanel.vue'),
            },
            {
                path: 'nova-proposta',
                name: 'createProposta',
                meta: {
                    module: 'propostas'
                },
                component: () => import(/!* webpackChunkName: "propostas" *!/ '@/views/propostas/PropostaDataPanel.vue'),
            },
            {
                path: ':uuid/editar-proposta',
                name: 'editProposta',
                props: true,
                meta: {
                    module: 'propostas'
                },
                component: () => import(/!* webpackChunkName: "propostas" *!/ '@/views/propostas/PropostaDataPanel.vue'),
            },

        ]
    },
    {
        path: '/users',
        name: 'usersList',
        meta: {
            module: 'users'
        },
        component: () => import(/!* webpackChunkName: "users" *!/ '../views/UsersList.vue')
    },*/
    {
        path: '/my-user',
        name: 'userArea',
        meta: {
            module: 'user'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/UserProfile.vue')
    },
    {
        path: '/dashboard',
        name: 'clienteDashboard',
        meta: {
            module: 'cliente'
        },
        component: () => import(/* webpackChunkName: "clientesdashboard" */ '@/views/DashboardPanel.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
