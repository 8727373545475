
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from '@/store'


import Modal from 'bootstrap/js/dist/modal'


/**
 *
 * - AVAILABLE TYPES:
 *  success - green header
 *  danger - red header
 *  primary - blue header
 *  warn - yellow header
 *  info - info header
 *
 */

export default defineComponent({
  name: 'DialogModal',

  mixins: [],

  setup: function () {
    // console.log('dialog modal context', ctx)
    const modalElement = ref(null)

    const store = useStore()

    let myModal: Modal;

    const state = reactive({
      // headerTitleStyle:/*'',*/'text-white bg-primary',
      // headerTitleStyle:/*'',*/'text-white bg-danger',
      headerTitleStyle: computed(() => {
        const type = store.getters.getDialogData.type
        switch (type) {
          case 'success':
            return 'text-white bg-success'
          case 'danger':
            return 'text-white bg-danger'
          case 'primary':
            return 'text-white bg-primary'
          case 'warn':
            return 'text-white bg-warning'
          case 'info':
            return 'text-white bg-info'
        }
        return ''
      }),
      show: computed(() => store.getters.getDialogData.show),
      btnStyle: computed(() => {
        let btnStyle = store.getters.getDialogData.btnStyle
        if (!btnStyle) {
          const type = store.getters.getDialogData.type
          switch (type) {
            case 'danger':
              btnStyle = 'btn-danger'
              break
            case 'info':
              btnStyle = 'btn-info'
              break
            case 'success':
              btnStyle = 'btn-success'
              break
            case 'warn':
              btnStyle = 'btn-warning'
              break
            default:
              btnStyle = 'btn-primary'
              break
          }
        }
        return btnStyle
      }),
    })

    onMounted(() => {
      myModal = new Modal('#the_modal', {
        keyboard: false
      })
      /*document.getElementById('the_modal').addEventListener('hidden.bs.modal', function (/!*event*!/) {
        store.commit('showDialog', false)
      })*/
    })


    watch(() => store.getters.getDialogData.show,
      (val) => {
        val ? myModal.show() : myModal.hide()
      }
    )

    const title = computed(() => store.getters.getDialogData.title)
    const message = computed(() => store.getters.getDialogData.message)
    const successCallback = computed(() => store.getters.getDialogData.successCallback)
    const btnStyle = computed(() => store.getters.getDialogData.btnStyle)


    function answerOk() {
      if (successCallback.value !== null) {
        successCallback.value()
      }
      store.commit('showDialog', false)
    }

    function answerCancel() {
      store.commit('showDialog', false)
    }

    return {
      title,
      message,
      state,
      modalElement,
      answerOk,
      answerCancel,
      btnStyle,
    }


  }


})
