
import {computed, onMounted, reactive} from "vue";
import {useStore} from '@/store'
import axios from "axios";
import {useRoute} from "vue-router";

import NavbarMenu from "@/components/NavbarMenu.vue";
import MaskModal from "@/components/MaskModal.vue";
import AlertPopup from "@/components/AlertPopup.vue";
import DialogModal from "@/components/DialogModal.vue";
import AckPopup from "@/components/AckPopup.vue";
// import {init} from "@/utils/theme";
// import '@/assets/js/theme.js';


export default {

    components: {NavbarMenu, AlertPopup, MaskModal, DialogModal, AckPopup},

    setup() {

        const store = useStore();
        const route = useRoute();

        const state = reactive({
            appVersion: computed<string>(() => store.getters.getLoggedUser ? store.getters.getLoggedUser.appVersion : '0'),
            userName: computed<string>(() => store.getters.getLoggedUser ? store.getters.getLoggedUser.name : '--'),
            showMenuItems: computed<boolean>(() => !!store.getters.getLoggedUser && route.name !== 'login' && route.name !== 'recoverPassword'),
        })

        onMounted(() => {
            store.dispatch('loadWhoAmI');
            // init();
        })

        const logoutHandler = () => {
            axios.post('/logout')
            window.location.assign('/jbay-login')
        }

        return {
            state,
            logoutHandler
        }
    }
}

