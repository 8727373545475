
const SPRING_DATA_URL = '/api/data';

export const USERS_API_URL = SPRING_DATA_URL + '/users';
export const CLIENTES_API_URL = SPRING_DATA_URL + '/clientes';
export const PROJETOS_API_URL = SPRING_DATA_URL + '/projetoes';
export const GENERAL_SETTINGS_API_URL = SPRING_DATA_URL + '/generalSettings';
export const PROPOSTAS_API_URL = SPRING_DATA_URL + '/propostas';

const SPRING_REST_URL = '/api/rest/v0';

export const PROPOSTAS_REST_API_URL = SPRING_REST_URL + '/propostas';
export const USERS_REST_API_URL = SPRING_REST_URL + '/users';
export const CLIENTS_REST_API_URL = SPRING_REST_URL + '/clients';
export const PROJECTS_REST_API_URL = SPRING_REST_URL + '/projects';


