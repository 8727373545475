import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {key, store} from './store'

import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/main.css';
import '@/assets/css/jbay-styles.css';
// import '@/assets/js/theme.js';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleUp,
    faArrowLeft,
    faBars,
    faBell,
    faBook,
    faCheck,
    faChevronDown,
    faClipboardUser,
    faCogs,
    faCopy,
    faDiagramProject,
    faDonate,
    faDownload,
    faEnvelope,
    faExclamationTriangle,
    faFileAlt,
    faFilePdf,
    faGears,
    faIdCard,
    faList,
    faListCheck,
    faPenToSquare,
    faPlus,
    faPowerOff,
    faRemove,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faSeedling,
    faSignOutAlt,
    faSpinner,
    faSquareCaretDown,
    faSquareCaretUp,
    faTachometerAlt,
    faTrashCan,
    faUser,
    faUsers,
    faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {faClock, faFloppyDisk, faSave} from '@fortawesome/free-regular-svg-icons'

axios.defaults.withCredentials = true;


library.add(faSpinner, faPlus, faArrowLeft, faUsers, faFloppyDisk, faTrashCan, faXmark, faPenToSquare, faPowerOff,
    faSeedling, faUser, faGears, faBook, faCheck, faClipboardUser, faListCheck, faDiagramProject, faFilePdf, faDownload,
    faSquareCaretUp, faSquareCaretDown, faSearch, faBars, faBell, faFileAlt, faDonate, faExclamationTriangle, faEnvelope,
    faCogs, faList, faSignOutAlt, faAngleUp, faTachometerAlt, faIdCard, faSave, faRemove, faCopy, faChevronDown, faSearchPlus,
    faSearchMinus, faClock
)


createApp(App)
    .use(store, key)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
