import {CLIENTES_API_URL, PROJETOS_API_URL, PROPOSTAS_REST_API_URL} from "@/urls";
import axios from "axios";
import {Cliente} from "@/interfaces/clientes";
import {Projeto} from "@/interfaces/projetos";
import {Proposta} from "@/interfaces/propostas";

interface State {
    client: Cliente
    projectDetail: Projeto | null,
    proposalDetail: Proposta | null,
    projectProposals: Proposta[],
}

const state: State = {
    client: {
        name: '',
        active: false,
        created: '',
        togglId: null,
        invoiceExpressId: null,
        photoSysfilename: null,
    },
    projectDetail: null,

    projectProposals: [],

    proposalDetail: null,
}

const getters = {
    getClientDetails: (state: State): Cliente => state.client,
    getProjectDetail: (state: State): Projeto | null => state.projectDetail,
    getProposalDetail: (state: State): Proposta | null => state.proposalDetail,
    getProjectProposals: (state: State): Proposta[] => state.projectProposals,
}

const mutations = {
    setClientDetails(state: State, client: Cliente) {
        state.client = client;
    },
    setProjetosDetail(state: State, projeto: Projeto) {
        state.projectDetail = projeto;
    },
    setProposalDetail(state: State, proposal: Proposta) {
        state.proposalDetail = proposal;
    },
    setProjectProposals(state: State, list: Proposta[]) {
        state.projectProposals = list;
    },

}

const actions = {

    // eslint-disable-next-line
    loadClientData(context: any, uuid: string) {
        context.commit('showMask', true, {root: true});
        const url = `${CLIENTES_API_URL}/search/findClienteByUuid?uuid=${uuid}`;
        axios.get(url)
            .then(response => {
                // console.debug('load Cliente response: ', response);
                context.commit('setClientDetails', response.data);
            })
            .catch((error) => {
                // console.error('create Cliente error: ', error)
                context.commit('showAlert', {
                    title: 'Error!',
                    message: 'Ocorreu um erro no carregamento dos dados de cliente!',
                    helpText: error,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                context.commit('showMask', false, {root: true});
            })
    },

    // eslint-disable-next-line
    loadProjectDetail(context: any, projectUuid: string) {
        context.commit('showMask', true, {root: true});
        context.dispatch('loadProjectProposals', projectUuid)
        const url = `${PROJETOS_API_URL}/search/findProjetoByUuid?uuid=${projectUuid}`;
        return axios.get(url)
            .then((response) => {
                // console.debug('load Projeto response: ', response)
                context.commit('setProjetosDetail', response.data);
                return response;
            })
            .catch((error) => {
                // console.error('create Projeto error: ', error)
                context.commit('showAlert', {
                    title: 'Error!',
                    message: 'Ocorreu um erro no carregamento dos dados do Projeto!',
                    helpText: error,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                context.commit('showMask', false, {root: true});
            })
    },

    // eslint-disable-next-line
    loadProjectProposals(context: any, projectUuid: string) {
        context.commit('showMask', true, {root: true});
        const url = `${PROPOSTAS_REST_API_URL}/${projectUuid}/project/proposals`;
        axios.get(url)
            .then((response) => {
                // console.debug('load Project Proposals response: ', response);
                if (response.data._embedded && response.data._embedded.propostaResources) {
                    context.commit('setProjectProposals', response.data._embedded.propostaResources);
                }
            })
            .catch((error) => {
                // console.error('create Projeto error: ', error)
                context.commit('showAlert', {
                    title: 'Error!',
                    message: 'Ocorreu um erro no carregamento da lista de Propostas!',
                    helpText: error,
                    type: 'error'
                }, {root: true});
            })
            .finally(() => {
                context.commit('showMask', false, {root: true});
            })


    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}


